import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getHeaderFooterData } from "../../../store/apps/footer";
import { useEffect } from "react";
import dynamic from "next/dynamic";

const Header = dynamic(() => import("../Header/Header"));
const Footer = dynamic(() => import("../Footer/Footer"));

const Layout = ({ children, webLogo }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		console.clear();
	}, []);

	const getHeaderFooterDataList = useCallback(() => {
		dispatch(getHeaderFooterData("data"));
	}, [dispatch]);

	useEffect(() => {
		getHeaderFooterDataList();
	}, []);

	return (
		<>
			<Header webLogo={webLogo} />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
